import { LabelPlacement } from "data/fields";
import { ReactNode } from "react";

export interface FormItemProps {
  label?: string;
  labelPlacement?: LabelPlacement;
  children?: ReactNode;
}

const FormItem: React.FC<FormItemProps> = ({
  label,
  labelPlacement = LabelPlacement.TOP,
  children,
}) => {
  return (
    <div
      className={`flex ${
        labelPlacement === LabelPlacement.INLINE
          ? "flex-row items-center"
          : "flex-col justify-start"
      }`}
    >
      {label && (
        <label
          className={`${
            labelPlacement === LabelPlacement.INLINE ? "ml-4" : "mb-2 mr-1"
          } text-gray-900 font-semibold`}
        >
          {label}
        </label>
      )}
      {children}
    </div>
  );
};

export default FormItem;
