import { useMutation } from "@apollo/client";
import Description from "components/PasswordManager/Description";
import PasswordForm from "components/PasswordManager/PasswordForm";
import { PasswordMethod } from "data/fields";
import { appStrings } from "data/strings";
import { OTP } from "graphql/queries/auth";
import { UPDATE_PASSWORD } from "graphql/queries/passwordManager";
import { useMask } from "hooks/useMask";
import { toNumber } from "lodash";
import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { userState } from "recoil/atoms/auth";
import raqam from "utils/raqam";

const PasswordManagerScreen: React.FC = () => {
  const [inVerification, setInVerification] = useState<boolean>(false);
  const [passwordMethod, setPasswordMethod] = useState(PasswordMethod.STATIC);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const user = useRecoilValue(userState);

  const [code, maskedCode, setCode] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return (
        !isNaN(toNumber(value)) || passwordMethod === PasswordMethod.STATIC
      );
    },
    defaultValue: "",
  });

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.currentTarget.value);
  };

  const [sendOTP, { loading: otpLoading }] = useMutation(OTP, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      if (data) {
        setInVerification(true);
        setPasswordMethod(PasswordMethod.SMSCODE);
      }
    },
  });

  const handleSetPassword = (event: FormEvent) => {
    event.preventDefault();
    if (!password) {
      toast.error(appStrings.passwordError);
      return;
    }
    sendOTP({
      variables: {
        username: user?.phone,
      },
    });
  };

  const [savePassword, { loading: setLoading }] = useMutation(UPDATE_PASSWORD, {
    context: {
      headers: {
        tsv: code,
      },
    },
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(appStrings.setPasswordSuccess);
      setPassword("");
      setCode("");
      setPasswordMethod(PasswordMethod.STATIC);
    },
  });

  const handleVerification = (event: FormEvent) => {
    event.preventDefault();
    if (!password) {
      toast.error(appStrings.passwordError);
      return;
    }
    savePassword({
      variables: {
        password,
      },
    });
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <Description />
      {!inVerification ? (
        <PasswordForm
          method={passwordMethod}
          value={password}
          loading={otpLoading}
          onChange={handlePasswordChange}
          onSetPassword={handleSetPassword}
          onVerify={handleVerification}
        />
      ) : (
        <PasswordForm
          method={passwordMethod}
          value={maskedCode}
          loading={setLoading}
          onChange={handleCodeChange}
          onSetPassword={handleSetPassword}
          onVerify={handleVerification}
        />
      )}
    </div>
  );
};

export default PasswordManagerScreen;
