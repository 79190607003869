export const appStrings = {
  appBrand: "قنداق",
  enterDreamlab: "ورود به مدرسه خواب",
  licenseCode: "لایسنس مدرسه خواب:",
  welcomePhrase: "مدیریت حساب کاربری در سامانه",
  username: "نام کاربری",
  usernamePlaceholder: "تلفن همراه یا ایمیل",
  phone: "شماره تلفن همراه",
  phonePlaceholder: "۰۹*********",
  password: "رمز عبور",
  passwordPlaceholder: "رمز عبور شما",
  code: "کد تایید",
  codePlaceholder: "کد تایید ۶ رقمی",
  email: "ایمیل",
  emailPlaceholder: "email@domain.com",
  firstName: "نام",
  firstNamePlaceholder: "نام به فارسی",
  lastName: "نام خانوادگی",
  lastNamePlaceholder: "نام خانوادگی به فارسی",
  whyLogin: {
    title: "به سامانه مدیریت حساب قنداق خوش اومدید!",
    description:
      "با ایجاد حساب کاربری تو سایت قنداق و ورود به حسابتون از خدمات بیشتری بهره مند میشین و می تونین از همه امکانات سایت ما استفاده کنید. بعضی از مزیت های ایجاد حساب کاربری موارد زیر هستن:",
    features: [
      "دسترسی به همه امکاناتی که ابزارک ها در اختیارتون قرار میدن",
      "مشاهده نتایج تحلیلی کوییز ها",
      "استفاده از امکانات شخصی سازی و ذخیره جستجوها",
      "مدیریت کودکان",
      "و ...",
    ],
  },
  loginHint:
    "برای ورود یا ثبت نام در سایت قنداق تلفن همراه یا ایمیل خودتون رو به عنوان نام کاربری وارد کنید.",
  codeHintPhone:
    "لطفا رمز یکبار مصرف ارسال شده به تلفن همراه خود را وارد نمایید. ",
  codeHintMail:
    'لطفا رمز یکبار مصرف ارسال شده به ایمیل خود را وارد نمایید. در صورت ندیدن ایمیل، حتما پوشه "اسپم" ایمیل خود را بررسی نمایید.',
  linkHintMail:
    'لطفا لینک تایید ارسال شده به ایمیلتون رو باز کنید. در صورتی که ایمیل ارسال شده توسط قنداق رو نمی بینید، حتما پوشه "اسپم" ایمیلتون رو بررسی کنید.',
  passwordHint:
    "لطفا رمز عبور خود را وارد نمایید. رمز عبور به حروف کوچک و بزرگ حساس می‌باشد.",
  getCode: "ورود با رمز یکبار مصرف",
  login: "ورود/ثبت نام",
  usernameError: "لطفا نام کاربری خودتون رو وارد کنید",
  passwordError: "لطفا فیلد های مد نظر رو تکمیل کنید",
  profileHint: {
    title: (name?: string) =>
      `${
        name || "کاربر عزیز"
      }، به صفحه مدیریت حساب کاربری خود در قنداق خوش آمدید.`,
    description:
      "اینجا می تونید اطلاعات پروفایل کاربری تون رو تکمیل کنید و یا تغییر بدید. اگر تمایل دارید که امکان ورود به حساب کاربری از طریق ایمیل براتون فراهم باشه یا در خبرنامه قنداق عضو باشید و خبر های قنداقی جدید رو زودتر از همه دریافت کنید، از طریق منوی اطلاعات تماس ایمیل خودتون رو وارد کنید و یا تغییر بدید.",
  },
  saveProfile: "ذخیره اطلاعات",
  sessionsHint: {
    title: "مدیریت نشست‌های فعال",
    description:
      "حساب کاربری شما در دستگاه‌ها و IP هایی که مشاهده می‌کنید فعال می‌باشد. در صورتی که هر کدام از این دستگاه‌ها متعلق به شما نیست می‌توانید با کلیک بر روی دکمه ضربدر آن نشست را غیرفعال کنید.",
  },
  passwordManagerHint: {
    title: "تنظیم یا تغییر رمز عبور",
    whyDescription:
      "با تنظیم رمز عبور می‌تونید بدون نیاز به رمز یکبار مصرف پیامکی یا ایمیلی به حساب کاربری تون وارد بشید.",
    setDescription:
      "برای تنظیم یا تغییر رمز عبور لازمه از طریق کد تایید پیامکی، فرآیند احراز هویت رو انجام بدید.",
  },
  disableAllSessions: "بستن تمام نشست‌ها",
  changePasswordHint:
    "لطفا رمز عبور خودتون رو وارد کنید. رمز عبور باید حداقل ۸ کاراکتر باشه.",
  changePassword: "تنظیم یا تغییر رمز عبور",
  passwordStrength: {
    strong: "قوی",
    medium: "میانه",
    weak: "ضعیف",
  },
  infatnsHint: {
    title: "مدیریت بچه‌ها",
    description:
      "با وارد کردن اطلاعات فرزندانتون می‌تونید از امکانات و ابزارک های قنداق به صورت شخصی سازی شده و اختصاصی استفاده کنید. البته برای استفاده از امکانات پایه ای قنداق نیازی به اضافه کردن کودکتون ندارید.",
    note:
      "قابل ذکر است تمامی اطلاعاتی که وارد می‌کنید نزد قنداق محرمانه است و تحت هیچ شرایطی قنداق اقدام به نشر و یا تکثیر آن نمی‌نماید.",
  },
  addInfant: "اضافه کردن بچه",
  emptyInfants:
    'تا حالا اطلاعات کودکتون رو وارد نکردید. برای شروع روی "اضافه کردن بچه" کلیک کنید',
  infantFormHint: {
    title: (isEditing: boolean) =>
      isEditing ? "ویرایش بچه" : "اضافه کردن بچه",
    description: (isEditing: boolean) =>
      isEditing
        ? "از طریق فرم مقابل می‌توانید نسبت به افزودن بچه اقدام نمایید. جهت ارائه خدمات با کیفیت و کاربردی تر، لطفا اطلاعات خواسته شده را به صورت دقیق وارد نمایید."
        : "از طریق فرم مقابل می‌توانید بچه خود را حذف و یا ویرایش نمایید. در نظر داشته باشید در صورت حذف بچه، تمامی اطلاعات مربوط به آن به صورت دائمی از بین خواهند رفت.",
  },
  babyName: "نام فرزند",
  babyNamePlaceholder: "به فارسی",
  birthdate: "تاریخ تولد",
  loading: "در حال دریافت اطلاعات...",
  sanctomError: "مشکل در ارتباط با سرور",
  gender: "جنسیت",
  createInfantSuccess: "فرزند شما با موفقیت افزوده شد",
  yearOld: "ساله",
  monthOld: "ماهه",
  dayOld: "روزه",
  deleteInfantNote:
    "آیا از حذف این بچه اطمینان دارید؟ در نظر داشته باشید در صورت تایید تمامی اطلاعات آن حذف خواهد شد.",
  delete: "حذف",
  cancel: "لغو",
  deleteInfantSuccess: "بچه مورد نظر حذف شد",
  setPasswordSuccess: "رمز عبور با موفقیت تنظیم شد",
  changeEmail: "تغییر ایمیل",
  saveEmailSuccess: "ایمیل شما با موفقیت ثبت شد",
  saveInfoSuccess: "اطلاعات شما با موفقیت به روز شد",
  logout: "خروج از حساب کاربری",
  loginSuccess: "ورود موفقیت آمیز بود!",
  contact: {
    title: "اطلاعات تماس و ورود",
    description:
      "با ثبت ایمیل و اعتبارسنجی اون، می‌تونید با استفاده از ایمیل وارد حساب کاربرتون بشید. علاوه بر اون می‌تونید از امکانات ایمیلی قنداق مثل خبرنامه بهره مند بشید و جزو اولین کسایی باشید که از امکانات جدید قنداق باخبر می‌شن!",
  },
  resendEmailLink: "ارسال مجدد لینک فعالسازی",
  invoice: {
    title: "پرداخت با موفقیت انجام شد",
    description:
      "پرداخت شما با موفقیت انجام شد. جهت ورود به مدرسه خواب، می بایست از کد لایسنس روبرو استفاده کنید.",
  },
  paymentFailed: {
    title: "پرداخت ناموفق",
    description:
      "پرداخت شما ناموفق بود. در صورتی که مبلغی از حساب شما کسر شده است تا ۷۲ ساعت آینده به حساب شما مرجوع خواهد شد.",
  },
  return: "بازگشت به مدرسه خواب",
  verEmailSent: "ایمیل تایید برای شما ارسال شد",
  price: "مبلغ",
  toman: "تومان",
  refid: "کد رهگیری",
};
