import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      first_name
      last_name
      email
      email_verified_at
      mobile
      infants {
        id
        name
        birthday
        gender
      }
    }
  }
`;

export const REGISTER = gql`
  mutation AUTH_register($username: String!) {
    AUTH_register(username: $username) {
      otp_issued
    }
  }
`;

export const LOGIN = gql`
  mutation AUTH_login($username: String!, $password: String!) {
    AUTH_login(username: $username, password: $password) {
      first_name
      last_name
      email
      email_verified_at
      mobile
      infants {
        id
        name
        birthday
        gender
      }
    }
  }
`;

export const OTP = gql`
  mutation AUTH_otp($username: String!) {
    AUTH_otp(username: $username)
  }
`;

export const LOGOUT = gql`
  mutation AUTH_logout {
    AUTH_logout
  }
`;
