import { appStrings } from "data/strings";
import { MdChildFriendly } from "react-icons/md";

export interface PayloadProps {
  code?: string;
}

const Payload: React.FC<PayloadProps> = ({ code }) => {
  return (
    <div className="w-full lg:w-1/2 md:px-4 mt-12 lg:mt-0 text-center">
      <p className="text-green-800 mb-4">{appStrings.licenseCode}</p>
      <p className="bg-green-100 py-4 text-green-800 mb-8 text-lg font-semibold rounded-lg">
        {code}
      </p>
      <a
        href={`${process.env.REACT_APP_DREAMLAB_URL}/platform/enter-license?code=${code}`}
        className="flex items-center justify-center text-base md:text-lg border-2 border-purple-800 text-purple-800 rounded-lg py-1 px-2 transition duration-300 hover:text-pink-700 hover:border-pink-700"
      >
        <span className="inline-block ml-2">
          <MdChildFriendly />
        </span>
        <span>{appStrings.enterDreamlab}</span>
      </a>
    </div>
  );
};

export default Payload;
