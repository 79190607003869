import { useQuery } from "@apollo/client";
import Description from "components/Invoice/Description";
import Payload from "components/Invoice/Payload";
import PageLoader from "components/Shared/utils/PageLoader";
import { GET_INVOICE } from "graphql/queries/invoice";
import { useState } from "react";
import { useParams } from "react-router";

const InvoiceScreen: React.FC = () => {
  const { uuid } = useParams<any>();
  const [code, setCode] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState<string | undefined>(undefined);
  const [refID, setRefID] = useState<string | undefined>(undefined);

  //eslint-disable-next-line
  const { error, loading, refetch } = useQuery(GET_INVOICE, {
    variables: {
      uuid,
    },
    onCompleted: (data) => {
      const serverData = data.FINANCE_get_invoice;
      if (!serverData) return;
      const tomanTotal = (Number(serverData.total) / 10).toString();
      setTotal(tomanTotal);
      if (
        serverData.items &&
        serverData.items.length > 0 &&
        serverData.items[0].payload
      ) {
        const codeObject = JSON.parse(serverData.items[0].payload);
        if (codeObject) {
          setCode(codeObject.code);
        }
      }
      if (
        serverData.payments &&
        serverData.payments.length > 0 &&
        serverData.payments[0].ref_id
      ) {
        setRefID(serverData.payments[0].ref_id);
      }
    },
  });

  return (
    <div className="flex flex-col lg:flex-row">
      <PageLoader loading={loading} error={error}>
        <Description total={total} refID={refID} />
        <Payload code={code} />
      </PageLoader>
    </div>
  );
};

export default InvoiceScreen;
