import { appStrings } from "data/strings";
import { toPersian } from "utils/raqam";

export interface DescriptionProps {
  refID?: string;
  total?: string;
}

const Description: React.FC<DescriptionProps> = ({ refID, total }) => {
  return (
    <div className="w-full lg:w-1/2 flex flex-col space-y-6 md:px-4 lg:mt-0">
      <p className="text-lg md:text-xl font-semibold">
        {appStrings.invoice.title}
      </p>
      <p className="text-sm md:text-base text-justify leading-relaxed">
        {appStrings.invoice.description}
      </p>
      <p className="bg-gray-100 rounded px-4 py-1 text-center">
        <span className="font-semibold">{appStrings.price}:</span>{" "}
        {toPersian(total || 0, { comma: true })} {appStrings.toman}
      </p>
      <p className="bg-gray-100 rounded px-4 py-1 text-center">
        <span className="font-semibold">{appStrings.refid}:</span>{" "}
        {toPersian(refID || "-")}
      </p>
    </div>
  );
};

export default Description;
