import { useMutation } from "@apollo/client";
import Description from "components/Login/Description";
import PasswordForm from "components/Login/PasswordForm";
import UsernameForm from "components/Login/UsernameForm";
import { PasswordMethod, UsernameMethod } from "data/fields";
import { appStrings } from "data/strings";
import { LOGIN, OTP, REGISTER } from "graphql/queries/auth";
import { useMask } from "hooks/useMask";
import { toNumber } from "lodash";
import { ChangeEvent, FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authenticationState, userState } from "recoil/atoms/auth";
import { infantsState } from "recoil/atoms/infant";
import { redirectActionState, redirectUrlState } from "recoil/atoms/redirect";
import { checkUsernameMethod } from "utils/checkUsernameMethod";
import { infantArrayParser } from "utils/infantParser";
import raqam from "utils/raqam";
import { userParser } from "utils/userParser";

const LoginScreen: React.FC = () => {
  const [inVerification, setInVerification] = useState<boolean>(false);
  const [usernameMethod, setUsernameMethod] = useState(UsernameMethod.PHONE);
  const [passwordMethod, setPasswordMethod] = useState(PasswordMethod.STATIC);
  const setUser = useSetRecoilState(userState);
  const setInfants = useSetRecoilState(infantsState);
  const setAuth = useSetRecoilState(authenticationState);
  const originUrl = useRecoilValue(redirectUrlState);
  const originAction = useRecoilValue(redirectActionState);
  const { push } = useHistory();

  const [username, maskedUsername, setUsername] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return true;
    },
    defaultValue: "",
  });

  const [password, maskedPassword, setPassword] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return (
        !isNaN(toNumber(value)) || passwordMethod === PasswordMethod.STATIC
      );
    },
    defaultValue: "",
  });

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const userInput = event.currentTarget.value;
    const isDeciderChar = userInput.length === 1;
    if (isDeciderChar) {
      const method = checkUsernameMethod(userInput);
      setUsernameMethod(method);
    }
    setUsername(event.currentTarget.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const [register, { loading }] = useMutation(REGISTER, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      data.AUTH_register.otp_issued
        ? setPasswordMethod(PasswordMethod.SMSCODE)
        : setPasswordMethod(PasswordMethod.STATIC);
      setInVerification(true);
    },
  });

  const handleSubmitUsername = (event: FormEvent) => {
    event.preventDefault();
    if (!username) {
      toast.error(appStrings.usernameError);
      return;
    }
    register({
      variables: {
        username: username,
      },
    });
  };

  const [login, { loading: loginLoading }] = useMutation(LOGIN, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const parsedUser = userParser(data.AUTH_login);
      const parsedInfants = infantArrayParser(data.AUTH_login.infants);
      setUser(parsedUser);
      setInfants(parsedInfants);
      setAuth(true);
      if (originAction && originUrl) {
        push("/infants");
        return;
      }
      if (originUrl) {
        const url = originUrl;
        window.location.assign(url);
        toast.success(appStrings.loginSuccess);
        return;
      }
    },
  });

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();
    if (!password) {
      toast.error(appStrings.passwordError);
      return;
    }
    login({
      variables: {
        username,
        password,
      },
    });
  };

  const [getOtp, { loading: otpLoading }] = useMutation(OTP, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      if (data) {
        setPassword("");
        setPasswordMethod(PasswordMethod.SMSCODE);
      }
    },
  });

  const handleGetCode = () => {
    if (!username) {
      toast.error(appStrings.usernameError);
      return;
    }
    getOtp({
      variables: {
        username,
      },
    });
  };

  return (
    <div className="flex flex-col-reverse lg:flex-row">
      <Description />
      {!inVerification ? (
        <UsernameForm
          method={usernameMethod}
          value={
            usernameMethod === UsernameMethod.PHONE ? maskedUsername : username
          }
          loading={loading}
          onChange={handleUsernameChange}
          onSubmit={handleSubmitUsername}
        />
      ) : (
        <PasswordForm
          method={passwordMethod}
          value={
            passwordMethod === PasswordMethod.STATIC ? password : maskedPassword
          }
          loading={loginLoading}
          otpLoading={otpLoading}
          onChange={handlePasswordChange}
          onGetCode={handleGetCode}
          onLogin={handleLogin}
        />
      )}
    </div>
  );
};

export default LoginScreen;
