import Alert from "components/Layout/Alert";
import PrimaryButton from "components/Shared/buttons/PrimaryButton";
import { AlertTypes } from "data/alertTypes";
import { UsernameMethod } from "data/fields";
import { appStrings } from "data/strings";
import { ChangeEvent, FormEvent } from "react";
import Username from "./Username";

export interface UsernameFormProps {
  method: UsernameMethod;
  value?: string;
  loading: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent) => void;
}

const UsernameForm: React.FC<UsernameFormProps> = ({
  method,
  value,
  loading,
  onChange,
  onSubmit,
}) => {
  return (
    <div className="w-full lg:w-1/2 md:px-4">
      <Alert type={AlertTypes.INFO} text={appStrings.loginHint} />
      <form className="flex flex-col space-y-4 mt-8" onSubmit={onSubmit}>
        <Username method={method} value={value} onChange={onChange} />
        <PrimaryButton loading={loading}>{appStrings.login}</PrimaryButton>
      </form>
    </div>
  );
};

export default UsernameForm;
