import { appStrings } from "data/strings";
import logo from "images/logo.svg";
import Navbar from "navigation/Navbar";
import { useRecoilValue } from "recoil";
import { authenticationState } from "recoil/atoms/auth";

const Header: React.FC = () => {
  const isAuthenticated = useRecoilValue(authenticationState);

  return (
    <div className="flex flex-col space-y-3 lg:flex-row lg:space-y-0 items-center p-4">
      <div className="w-full lg:w-1/2 flex flex-row items-center">
        <a href="https://ghondagh.com">
          <img src={logo} alt={appStrings.appBrand} className="w-8" />
        </a>
        <div className="mr-4">
          <p>
            <span className="inline-block font-semibold text-gray-700">
              {appStrings.welcomePhrase}
            </span>
            <span className="inline-block font-semibold text-lg text-purple-700 mr-1">
              {appStrings.appBrand}
            </span>
          </p>
        </div>
      </div>
      {isAuthenticated && (
        <div className="w-full lg:w-1/2">
          <Navbar />
        </div>
      )}
    </div>
  );
};

export default Header;
