import List from "components/Infants/List";
import Description from "components/Infants/Description";
import { useMutation } from "@apollo/client";
import { DELETE_INFANT } from "graphql/queries/infants";
import { useState } from "react";
import { toast } from "react-toastify";
import { appStrings } from "data/strings";
import DeleteConfirmation from "components/Infants/DeleteConfirmation";
import { useRecoilState } from "recoil";
import { infantsState } from "recoil/atoms/infant";

const InfantsScreen: React.FC = () => {
  const [deletingID, setDeletingID] = useState<string | undefined>(undefined);
  const [infants, setInfants] = useRecoilState(infantsState);

  const [deleteInfant, { loading: deleteLoading }] = useMutation(
    DELETE_INFANT,
    {
      variables: {
        infant_id: deletingID ? parseInt(deletingID) : -1,
      },
      onError: (error) => toast.error(error.message),
      onCompleted: () => {
        const newInfants = infants.filter((item) => item.id !== deletingID);
        setInfants(newInfants);
        toast.success(appStrings.deleteInfantSuccess);
        setDeletingID(undefined);
      },
    }
  );

  const confirmDeleteInfant = () => {
    deleteInfant();
  };

  const handleCancelDelete = () => {
    setDeletingID(undefined);
  };

  const handleDeleteInfant = (id: string) => {
    setDeletingID(id);
  };

  if (deletingID) {
    return (
      <DeleteConfirmation
        loading={deleteLoading}
        onDelete={confirmDeleteInfant}
        onCancel={handleCancelDelete}
      />
    );
  }

  return (
    <div className="flex flex-col lg:flex-row">
      <Description />
      <List infants={infants} onDelete={handleDeleteInfant} />
    </div>
  );
};

export default InfantsScreen;
