import { atom } from "recoil";

export const redirectUrlState = atom<string | undefined>({
  key: "redirectUrl",
  default: undefined,
});

export const redirectActionState = atom<string | undefined>({
  key: "redirectAction",
  default: undefined,
});
