import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

export interface LinkButtonProps {
  children: ReactNode;
  url: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({ children, url }) => {
  return (
    <NavLink
      to={url}
      className="bg-blue-500 border-2 border-blue-500 text-white text-center py-2 px-6 rounded-xl font-semibold transition duration-300 hover:bg-blue-800 hover:border-blue-800"
    >
      {children}
    </NavLink>
  );
};

export default LinkButton;
