import PhoneInput from "components/Shared/fields/PhoneInput";
import ShortTextInput from "components/Shared/fields/ShortTextInput";
import { UsernameMethod } from "data/fields";
import { appStrings } from "data/strings";
import { ChangeEvent } from "react";

export interface UsernameProps {
  method: UsernameMethod;
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Username: React.FC<UsernameProps> = ({ method, value, onChange }) => {
  if (method === UsernameMethod.PHONE) {
    return (
      <PhoneInput
        name="username"
        label={appStrings.username}
        placeholder={appStrings.usernamePlaceholder}
        maskedValue={value}
        autoFocus={true}
        onChange={onChange}
      />
    );
  }

  return (
    <ShortTextInput
      name="username"
      type="email"
      label={appStrings.username}
      placeholder={appStrings.usernamePlaceholder}
      value={value}
      autoFocus={true}
      onChange={onChange}
    />
  );
};

export default Username;
