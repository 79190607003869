import { ILoginLink } from "models/LoginLink";
import { MdWidgets, MdChildFriendly, MdChromeReaderMode } from "react-icons/md";
import { originApps } from "./originApps";

export const loginLinks: ILoginLink[] = [
  {
    title: "ابزارک ها",
    url: originApps["widget"],
    icon: <MdWidgets />,
  },
  {
    title: "کوییزها",
    url: originApps["quiz"],
    icon: <MdChromeReaderMode />,
  },
  // {
  //   title: "مشاوره",
  //   url: originApps["consulting"],
  //   icon: <MdRingVolume />,
  // },
  {
    title: "مدرسه خواب",
    url: originApps["sleepschool"],
    icon: <MdChildFriendly />,
  },
];
