import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import { appStrings } from "data/strings";
import moment from "moment-jalaali";

export const toGeorgian = (date: DayValue) => {
  const dateString = `${date?.year}-${date?.month}-${date?.day}`;
  const result = moment(dateString, "jYYYY-jM-jD").toISOString(true);
  return result;
};

export const toAge = (date: string) => {
  const dayDiff = moment().diff(date, "days");
  if (dayDiff > 365) {
    const yearDiff = moment().diff(date, "years");
    return `${yearDiff} ${appStrings.yearOld}`;
  }
  if (dayDiff > 30) {
    const monthDiff = moment().diff(date, "months");
    return `${monthDiff} ${appStrings.monthOld}`;
  }
  return `${dayDiff} ${appStrings.dayOld}`;
};
