import { useMutation } from "@apollo/client";
import Description from "components/Profile/Description";
import UserForm from "components/Profile/UserForm";
import { appStrings } from "data/strings";
import { LOGOUT } from "graphql/queries/auth";
import { EDIT_PROFILE } from "graphql/queries/profile";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authenticationState, userState } from "recoil/atoms/auth";

const Profile: React.FC = () => {
  const setAuth = useSetRecoilState(authenticationState);
  const [user, setUser] = useRecoilState(userState);
  const [firstName, setFirstName] = useState<string | undefined>(
    user?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(user?.lastName);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
    }
  }, [user]);

  const handleFirstnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.currentTarget.value);
  };

  const handleLastnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.currentTarget.value);
  };

  const [editProfile, { loading: editLoading }] = useMutation(EDIT_PROFILE, {
    variables: {
      input: {
        first_name: firstName,
        last_name: lastName,
      },
    },
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(appStrings.saveInfoSuccess);
      user && setUser({ ...user, firstName, lastName });
    },
  });

  const handleSaveUserInfo = (event: FormEvent) => {
    event.preventDefault();
    editProfile();
  };

  const [logoutSelf, { loading: logoutLoading }] = useMutation(LOGOUT, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      if (data.AUTH_logout) {
        setAuth(false);
        setUser(undefined);
        return;
      }
    },
  });

  return (
    <div className="flex flex-col lg:flex-row">
      <Description loading={logoutLoading} onLogout={logoutSelf} />
      <UserForm
        phone={user?.phone || ""}
        firstName={firstName}
        lastName={lastName}
        infoLoading={editLoading}
        handleFirstnameChange={handleFirstnameChange}
        handleLastnameChange={handleLastnameChange}
        onSave={handleSaveUserInfo}
      />
    </div>
  );
};

export default Profile;
