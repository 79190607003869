import { toAge } from "./dateParser";

export const infantParser = (infantObject: any) => {
  const parsedObject = {
    id: infantObject.id,
    name: infantObject.name,
    age: toAge(infantObject.birthday),
  };
  return parsedObject;
};

export const infantArrayParser = (infants: any[]) => {
  const parsedInfants = infants.map((item: any) => infantParser(item));
  return parsedInfants;
};
