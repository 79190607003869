import PrimaryButton from "components/Shared/buttons/PrimaryButton";
import PhoneInput from "components/Shared/fields/PhoneInput";
import ShortTextInput from "components/Shared/fields/ShortTextInput";
import { appStrings } from "data/strings";
import { ChangeEvent, FormEvent } from "react";
import { FaMailBulk } from "react-icons/fa";
import { toPersian } from "utils/raqam";

export interface ContactFormProps {
  phone: string;
  email?: string;
  emailConfirmation?: boolean;
  loading: boolean;
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSave: (event: FormEvent) => void;
}

const ContactForm: React.FC<ContactFormProps> = ({
  phone,
  email,
  emailConfirmation,
  loading,
  handleEmailChange,
  onSave,
}) => {
  return (
    <div className="w-full lg:w-1/2 md:px-4 mt-12 lg:mt-0">
      <form className="flex flex-col space-y-4" onSubmit={onSave}>
        <PhoneInput
          name="phone"
          label={appStrings.phone}
          disabled={true}
          maskedValue={toPersian(phone)}
        />
        <ShortTextInput
          name="email"
          type="email"
          label={appStrings.email}
          placeholder={appStrings.emailPlaceholder}
          value={email}
          disabled={emailConfirmation}
          onChange={handleEmailChange}
        />
        {!emailConfirmation && (
          <div className="flex flex-col">
            <PrimaryButton loading={loading}>
              <FaMailBulk className="inline-block ml-2" />
              <span>{appStrings.changeEmail}</span>
            </PrimaryButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
