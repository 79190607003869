import CodeInput from "components/Shared/fields/CodeInput";
import ShortTextInput from "components/Shared/fields/ShortTextInput";
import { PasswordMethod } from "data/fields";
import { appStrings } from "data/strings";
import { ChangeEvent } from "react";

export interface PasswordProps {
  method: PasswordMethod;
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Password: React.FC<PasswordProps> = ({ method, value, onChange }) => {
  if (method === PasswordMethod.STATIC) {
    return (
      <ShortTextInput
        name="password"
        type="password"
        label={appStrings.password}
        placeholder={appStrings.passwordPlaceholder}
        value={value}
        onChange={onChange}
      />
    );
  }
  return (
    <CodeInput
      name="code"
      label={appStrings.code}
      placeholder={appStrings.codePlaceholder}
      maskedValue={value}
      onChange={onChange}
    />
  );
};

export default Password;
