import PrimaryButton from "components/Shared/buttons/PrimaryButton";
import ShortTextInput from "components/Shared/fields/ShortTextInput";
import { appStrings } from "data/strings";
import { IUser } from "models/User";
import { ChangeEvent, FormEvent } from "react";
import { FaCheckDouble } from "react-icons/fa";

export interface UserFormProps extends IUser {
  infoLoading: boolean;
  handleFirstnameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleLastnameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSave: (event: FormEvent) => void;
}

const UserForm: React.FC<UserFormProps> = ({
  firstName,
  lastName,
  infoLoading,
  handleFirstnameChange,
  handleLastnameChange,
  onSave,
}) => {
  return (
    <div className="w-full lg:w-1/2 md:px-4 mt-12 lg:mt-0">
      <form className="flex flex-col space-y-4" onSubmit={onSave}>
        <ShortTextInput
          name="firstName"
          label={appStrings.firstName}
          placeholder={appStrings.firstNamePlaceholder}
          value={firstName}
          onChange={handleFirstnameChange}
        />
        <ShortTextInput
          name="lastName"
          label={appStrings.lastName}
          placeholder={appStrings.lastNamePlaceholder}
          value={lastName}
          onChange={handleLastnameChange}
        />
        <div className="flex flex-col pt-2">
          <PrimaryButton loading={infoLoading}>
            <FaCheckDouble className="inline-block ml-2" />
            <span>{appStrings.saveProfile}</span>
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
