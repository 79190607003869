import { useMutation } from "@apollo/client";
import ContactForm from "components/Contact/ContactForm";
import Description from "components/Contact/Description";
import { appStrings } from "data/strings";
import { RESEND_EMAIL, UPDATE_EMAIL } from "graphql/queries/contact";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { userState } from "recoil/atoms/auth";

const ContactScreen: React.FC = () => {
  const [user, setUser] = useRecoilState(userState);
  const [email, setEmail] = useState<string | undefined>(user?.email);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!user?.emailConfirmation) {
      setEmail(event.currentTarget.value);
    }
  };

  const [changeEmail, { loading: emailLoading }] = useMutation(UPDATE_EMAIL, {
    variables: {
      email,
    },
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(appStrings.saveEmailSuccess);
      user && setUser({ ...user, email });
    },
  });

  const handleEmailSave = (event: FormEvent) => {
    event.preventDefault();
    changeEmail();
  };

  const [resendEmail, { loading }] = useMutation(RESEND_EMAIL, {
    onError: (error) => toast.error(error.message),
    onCompleted: (res) => {
      toast.success(appStrings.verEmailSent);
    },
  });

  return (
    <div className="flex flex-col lg:flex-row">
      <Description
        isConfirmed={!!user?.email ? user.emailConfirmation : true}
        loading={loading}
        onResend={resendEmail}
      />
      <ContactForm
        phone={user?.phone || ""}
        email={email}
        emailConfirmation={user?.emailConfirmation}
        loading={emailLoading}
        handleEmailChange={handleEmailChange}
        onSave={handleEmailSave}
      />
    </div>
  );
};

export default ContactScreen;
