import Alert from "components/Layout/Alert";
import LinkButton from "components/Shared/buttons/LinkButton";
import { AlertTypes } from "data/alertTypes";
import { appStrings } from "data/strings";
import { IInfant } from "models/Infant";
import { FaPlusCircle } from "react-icons/fa";
import Item from "./Item";

export interface ListProps {
  infants: IInfant[];
  onDelete: (id: string) => void;
}

const List: React.FC<ListProps> = ({ infants, onDelete }) => {
  return (
    <div className="w-full lg:w-1/2 md:px-4 mt-12 lg:mt-0">
      <div className="flex flex-col space-y-4">
        {infants.length === 0 && (
          <Alert text={appStrings.emptyInfants} type={AlertTypes.WARNING} />
        )}
        {infants.map((item, index) => (
          <Item
            key={index}
            isColored={index % 2 === 0}
            onDelete={onDelete}
            {...item}
          />
        ))}
        <LinkButton url="/infant/create">
          <FaPlusCircle className="inline-block ml-2 text-2xl" />
          <span>{appStrings.addInfant}</span>
        </LinkButton>
      </div>
    </div>
  );
};

export default List;
