import { ChangeEvent, FormEvent, useState } from "react";
import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import InfantForm from "components/Infants/InfantForm";
import FormDescription from "components/Infants/FormDescription";
import { useMutation } from "@apollo/client";
import { CREATE_INFANT } from "graphql/queries/infants";
import { toGeorgian } from "utils/dateParser";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { appStrings } from "data/strings";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { infantParser } from "utils/infantParser";
import { infantsState } from "recoil/atoms/infant";
import { redirectActionState, redirectUrlState } from "recoil/atoms/redirect";

const CreateInfant: React.FC = () => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [birthdate, setBirthdate] = useState<DayValue | undefined>(undefined);
  const [gender, setGender] = useState<string | undefined>(undefined);
  const setInfants = useSetRecoilState(infantsState);
  const originAction = useRecoilValue(redirectActionState);
  const originUrl = useRecoilValue(redirectUrlState);

  const { push } = useHistory();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const handleBirthdateChange = (value: DayValue) => {
    setBirthdate(value);
  };

  const handleGenderChange = (value: string) => {
    setGender(value);
  };

  const [createInfant, { loading }] = useMutation(CREATE_INFANT, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      if (originAction === "add-infant" && originUrl) {
        const url = originUrl;
        window.location.assign(url);
        toast.success(appStrings.loginSuccess);
        return;
      }
      const addedInfant = infantParser(data.create_infant);
      setInfants((currInfants) => [...currInfants, addedInfant]);
      toast.success(appStrings.createInfantSuccess);
      push("/infants");
    },
  });

  const handleCreate = (event: FormEvent) => {
    event.preventDefault();
    if (name && birthdate && gender) {
      createInfant({
        variables: {
          input: {
            name,
            gender,
            birthday: toGeorgian(birthdate),
            premature: 0,
          },
        },
      });
      return;
    }
    toast.error(appStrings.passwordError);
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <FormDescription isEditing={false} />
      <InfantForm
        name={name}
        birthdate={birthdate}
        gender={gender}
        loading={loading}
        handleNameChange={handleNameChange}
        handleBirthdateChange={handleBirthdateChange}
        handleGenderChange={handleGenderChange}
        onCreate={handleCreate}
      />
    </div>
  );
};

export default CreateInfant;
