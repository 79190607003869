import Alert from "components/Layout/Alert";
import PrimaryButton from "components/Shared/buttons/PrimaryButton";
import { AlertTypes } from "data/alertTypes";
import { PasswordMethod } from "data/fields";
import { appStrings } from "data/strings";
import { ChangeEvent, FormEvent } from "react";
import Password from "./Password";
import loadingSVG from "images/blackLoading.svg";

export interface PasswordFormProps {
  method: PasswordMethod;
  value?: string;
  loading: boolean;
  otpLoading: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onGetCode: () => void;
  onLogin: (event: FormEvent) => void;
}

const PasswordForm: React.FC<PasswordFormProps> = ({
  method,
  value,
  loading,
  otpLoading,
  onChange,
  onGetCode,
  onLogin,
}) => {
  const getHint = () => {
    switch (method) {
      case PasswordMethod.STATIC:
        return appStrings.passwordHint;
      case PasswordMethod.SMSCODE:
        return appStrings.codeHintPhone;
      case PasswordMethod.MAILCODE:
        return appStrings.codeHintMail;
      default:
        return appStrings.passwordHint;
    }
  };

  return (
    <div className="w-full lg:w-1/2 md:px-4">
      <Alert type={AlertTypes.INFO} text={getHint()} />
      <form className="flex flex-col space-y-4 mt-8" onSubmit={onLogin}>
        <Password method={method} value={value} onChange={onChange} />
        <PrimaryButton loading={loading}>{appStrings.login}</PrimaryButton>
        {method === PasswordMethod.STATIC && (
          <span
            onClick={otpLoading ? () => {} : onGetCode}
            className="text-center text-gray-700 cursor-pointer underline"
          >
            {appStrings.getCode}
            {otpLoading && (
              <img
                src={loadingSVG}
                className="w-7 inline-block mr-2"
                alt="..."
              />
            )}
          </span>
        )}
      </form>
    </div>
  );
};

export default PasswordForm;
