import Apollo from "graphql/Apollo";
import Navigator from "navigation/Navigator";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import UserIdentifier from "screens/Auth/UserIdentifier";

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Apollo>
        <UserIdentifier>
          <Navigator />
        </UserIdentifier>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Apollo>
    </RecoilRoot>
  );
};

export default App;
