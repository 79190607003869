import { IUser } from "models/User";
import { atom } from "recoil";

export const authenticationState = atom<boolean>({
  key: "authentication",
  default: true,
});

export const userState = atom<IUser | undefined>({
  key: "user",
  default: undefined,
});

export const tokenState = atom<string | undefined>({
  key: "token",
  default: undefined,
});
