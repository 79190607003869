import { loginLinks } from "data/loginLinks";
import { appStrings } from "data/strings";

const Description: React.FC = () => {
  return (
    <div className="w-full lg:w-1/2 flex flex-col space-y-6 md:px-4 mt-12 lg:mt-0">
      <p className="text-lg md:text-xl font-semibold">{appStrings.whyLogin.title}</p>
      <p className="text-sm md:text-base text-justify leading-relaxed">
        {appStrings.whyLogin.description}
      </p>
      <ul className="text-sm md:text-base list-inside list-disc leading-relaxed">
        {appStrings.whyLogin.features.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <div className="flex flex-col w-2/3 mx-auto space-y-4 justify-between">
        {loginLinks.map((item, index) => (
          <a
            key={index}
            href={item.url}
            className="flex items-center text-base md:text-lg border-2 border-purple-800 text-purple-800 rounded-lg py-1 px-2 transition duration-300 hover:text-pink-700 hover:border-pink-700"
          >
            <span className="inline-block ml-2">{item.icon}</span>
            <span>{item.title}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Description;
