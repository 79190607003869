import { gql } from "@apollo/client";

export const UPDATE_EMAIL = gql`
  mutation update_email($email: String!) {
    update_email(email: $email) {
      id
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation resend_email_verification {
    resend_email_verification {
      id
    }
  }
`;
