import { gql } from "@apollo/client";

export const CREATE_INFANT = gql`
  mutation create_infant($input: CreateInfantInput!) {
    create_infant(input: $input) {
      id
      name
      birthday
      gender
    }
  }
`;

export const DELETE_INFANT = gql`
  mutation delete_infant($infant_id: ID!) {
    delete_infant(infant_id: $infant_id) {
      id
    }
  }
`;
