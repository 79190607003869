import { ApolloError } from "@apollo/client";
import Alert from "components/Layout/Alert";
import { AlertTypes } from "data/alertTypes";
import { Fragment, ReactNode } from "react";
import cubeLoading from "images/cubeLoading.gif";

export interface PageLoaderProps {
  children: ReactNode;
  loading: boolean;
  error?: ApolloError | string;
  showError?: boolean;
}

const PageLoader: React.FC<PageLoaderProps> = ({
  children,
  loading,
  error,
  showError = true,
}) => {
  if (loading) {
    return (
      <div className="flex flex-col h-screen items-center justify-center">
        <img src={cubeLoading} alt="..." className="w-44" />
      </div>
    );
  }

  if (error && showError) {
    return (
      <Alert
        type={AlertTypes.ERROR}
        text={typeof error === "string" ? error : error.message}
      />
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default PageLoader;
