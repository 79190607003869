import { UsernameMethod } from "data/fields";

export const checkUsernameMethod = (input: string) => {
  const isEnteringNumber: boolean = /^[0-9]|[۰-۹]/.test(input);
  const isEnteringPlus: boolean = input === "+";
  const isPhone: boolean = isEnteringNumber || isEnteringPlus;
  if (isPhone) {
    return UsernameMethod.PHONE;
  }
  return UsernameMethod.EMAIL;
};
