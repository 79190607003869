import GhostButton from "components/Shared/buttons/GhostButton";
import PrimaryButton from "components/Shared/buttons/PrimaryButton";
import { ButtonType } from "data/buttonTypes";
import { appStrings } from "data/strings";

export interface DeleteConfirmationProps {
  loading: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  loading,
  onDelete,
  onCancel,
}) => {
  return (
    <div className="flex flex-col space-y-8">
      <p>{appStrings.deleteInfantNote}</p>
      <div className="flex justify-center">
        <div className="ml-4">
          <PrimaryButton type={ButtonType.BUTTON} onClick={onCancel}>
            {appStrings.cancel}
          </PrimaryButton>
        </div>
        <GhostButton
          loading={loading}
          type={ButtonType.BUTTON}
          onClick={onDelete}
        >
          {appStrings.delete}
        </GhostButton>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
