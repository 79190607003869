import { INavMenu } from "models/NavMenu";
import {
  FaUserCircle,
  FaBabyCarriage,
  FaLock,
  FaMailBulk,
} from "react-icons/fa";

export const menuItems: INavMenu[] = [
  {
    title: "پروفایل",
    url: "/profile",
    icon: <FaUserCircle />,
  },
  {
    title: "اطلاعات تماس",
    url: "/contact",
    icon: <FaMailBulk />,
  },
  {
    title: "بچه‌های من",
    url: "/infants",
    icon: <FaBabyCarriage />,
  },
  {
    title: "رمزعبور",
    url: "/password-manager",
    icon: <FaLock />,
  },
];
