import { ReactNode } from "react";
import { ButtonType } from "data/buttonTypes";
import loadingSVG from "images/loading.svg";

export interface PrimaryButtonProps {
  children: ReactNode;
  loading?: boolean;
  type?: ButtonType;
  onClick?: () => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  type = ButtonType.SUBMIT,
  loading = false,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={loading}
      className="bg-blue-500 border-2 border-blue-500 text-white py-2 px-6 rounded-xl font-semibold transition duration-300 hover:bg-blue-800 hover:border-blue-800"
    >
      {children}
      {loading && (
        <img src={loadingSVG} className="w-7 inline-block mr-2" alt="..." />
      )}
    </button>
  );
};

export default PrimaryButton;
