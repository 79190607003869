import { IInfant } from "models/Infant";
import { FaTrash } from "react-icons/fa";
import { toPersian } from "utils/raqam";

export interface ItemProps extends IInfant {
  isColored?: boolean;
  onDelete: (id: string) => void;
}

const Item: React.FC<ItemProps> = ({
  id,
  name,
  age,
  isColored = false,
  onDelete,
}) => {
  return (
    <div
      className={`${
        isColored ? "bg-purple-200" : "bg-gray-200"
      } rounded-lg p-2 flex flex-row items-center text-center`}
    >
      <span className="w-1/3">{name}</span>
      <span className="w-1/3">{toPersian(age)}</span>
      <span className="w-1/3">
        <FaTrash
          className="mx-auto text-red-600 cursor-pointer"
          onClick={() => onDelete(id)}
        />
      </span>
    </div>
  );
};

export default Item;
