import { ReactNode } from "react";
import Content from "./Content";

export interface ContainerProps {
  children: ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <div className="bg-gradient-to-l from-blue-500 via-purple-500 to-pink-400 h-full md:h-screen md:max-h-screen md:overflow-hidden flex justify-center items-center p-4">
      <Content>{children}</Content>
    </div>
  );
};

export default Container;
