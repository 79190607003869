import { ChangeEvent } from "react";
import { LabelPlacement } from "data/fields";
import FormItem from "./FormItem";

export interface PhoneInputProps {
  name: string;
  label?: string;
  labelPlacement?: LabelPlacement;
  placeholder?: string;
  className?: string;
  maskedValue?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  name,
  label,
  placeholder,
  className = "",
  maskedValue,
  autoFocus = false,
  labelPlacement = LabelPlacement.TOP,
  disabled = false,
  onChange,
}) => {
  return (
    <FormItem label={label} labelPlacement={labelPlacement}>
      <input
        id={name}
        dir="ltr"
        name={name}
        type="tel"
        placeholder={placeholder}
        value={maskedValue}
        autoFocus={autoFocus}
        onChange={onChange}
        autoComplete="off"
        disabled={disabled}
        className={`my-2 py-2 px-3 text-center rounded-xl placeholder-gray-400 text-gray-900 outline-none border-2 border-gray-300 transition duration-300 focus:border-blue-500 ${className}`}
      />
    </FormItem>
  );
};

export default PhoneInput;
