import { IOption } from "models/Option";

export const genders: IOption[] = [
  {
    label: "پسر",
    value: "boy",
  },
  {
    label: "دختر",
    value: "girl",
  },
  {
    label: "نامعلوم",
    value: "unknown",
  },
];
