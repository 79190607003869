export const userParser = (userObject: any) => {
  const {
    first_name,
    last_name,
    mobile,
    email,
    email_verified_at,
  } = userObject;

  const parsedUser = {
    firstName: first_name,
    lastName: last_name,
    phone: mobile,
    email: email,
    emailConfirmation: !!email_verified_at,
  };
  return parsedUser;
};
