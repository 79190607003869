import Container from "components/Layout/Container";
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import LoginScreen from "screens/Auth/Login";
import InfantsScreen from "screens/Infants";
import CreateInfant from "screens/Infants/CreateInfant";
import PasswordManagerScreen from "screens/PasswordManager";
import ProfileScreen from "screens/Profile";
import ContactScreen from "screens/Contact";
import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";
import InvoiceScreen from "screens/Invoice";
import PaymentFailed from "components/Payment/FailedPayment";

const Navigator: React.FC = () => {
  return (
    <BrowserRouter>
      <Container>
        <Switch>
          <ProtectedRoute path="/" exact component={ProfileScreen} />
          <ProtectedRoute path="/profile" component={ProfileScreen} />
          <ProtectedRoute path="/contact" component={ContactScreen} />
          <ProtectedRoute
            path="/password-manager"
            component={PasswordManagerScreen}
          />
          <ProtectedRoute path="/infants" component={InfantsScreen} />
          <ProtectedRoute path="/infant/create" component={CreateInfant} />
          <ProtectedRoute path="/invoices/:uuid" component={InvoiceScreen} />
          <ProtectedRoute path="/payment/failed" component={PaymentFailed} />
          <AuthRoute path="/login" component={LoginScreen} />
        </Switch>
      </Container>
    </BrowserRouter>
  );
};

export default Navigator;
