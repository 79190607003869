import { appStrings } from "data/strings";

export interface FormDescriptionProps {
  isEditing: boolean;
}

const FormDescription: React.FC<FormDescriptionProps> = ({ isEditing }) => {
  return (
    <div className="w-full lg:w-1/2 flex flex-col space-y-6 md:px-4 lg:mt-0">
      <p className="text-lg md:text-xl font-semibold">
        {appStrings.infantFormHint.title(isEditing)}
      </p>
      <p className="text-sm md:text-base text-justify leading-relaxed">
        {appStrings.infantFormHint.description(isEditing)}
      </p>
    </div>
  );
};

export default FormDescription;
