import { appStrings } from "data/strings";
import { MdChildFriendly } from "react-icons/md";

export interface PaymentFailedProps {}

const PaymentFailed: React.FC<PaymentFailedProps> = () => {
  return (
    <div className="flex flex-col space-y-6 text-center">
      <p className="text-2xl text-red-600 font-semibold">
        {appStrings.paymentFailed.title}
      </p>
      <p>{appStrings.paymentFailed.description}</p>
      <a
        href={`${process.env.REACT_APP_DREAMLAB_URL}`}
        className="flex items-center justify-center w-1/2 mx-auto text-base md:text-lg border-2 border-purple-800 text-purple-800 rounded-lg py-1 px-2 transition duration-300 hover:text-pink-700 hover:border-pink-700"
      >
        <span className="inline-block ml-2">
          <MdChildFriendly />
        </span>
        <span>{appStrings.return}</span>
      </a>
    </div>
  );
};

export default PaymentFailed;
