import { menuItems } from "data/menu";
import NavItem from "./NavItem";

const Navbar: React.FC = () => {
  return (
    <nav className="w-full flex">
      <div className="lg:mx-auto flex flex-row">
      {menuItems.map((item, index) => (
        <NavItem key={index} {...item} />
      ))}
      </div>
    </nav>
  );
};

export default Navbar;
