import { ChangeEvent } from "react";
import { LabelPlacement } from "data/fields";
import FormItem from "./FormItem";

export interface ShortTextInputProps {
  name: string;
  type?: string;
  label?: string;
  labelPlacement?: LabelPlacement;
  placeholder?: string;
  className?: string;
  value?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ShortTextInput: React.FC<ShortTextInputProps> = ({
  name,
  type = "text",
  label,
  placeholder,
  className = "",
  value,
  labelPlacement = LabelPlacement.TOP,
  autoFocus = false,
  disabled = false,
  onChange,
}) => {
  return (
    <FormItem label={label} labelPlacement={labelPlacement}>
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        autoFocus={autoFocus}
        autoComplete="off"
        disabled={disabled}
        dir={type === "password" ? "ltr" : "rtl"}
        className={`my-2 py-2 px-3 rounded-xl text-center placeholder-gray-400 text-gray-900 outline-none border-2 border-gray-300 transition duration-300 focus:border-blue-500 ${className}`}
      />
    </FormItem>
  );
};

export default ShortTextInput;
