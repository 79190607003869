import { Redirect, RouteProps } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticationState } from "recoil/atoms/auth";

export interface AuthRouteProps extends RouteProps {}

const AuthRoute: React.FC<AuthRouteProps> = ({ component }) => {
  const isAuthenticated = useRecoilValue(authenticationState);

  if (isAuthenticated) {
    return <Redirect to="/profile" />;
  }

  const Component: React.FC = component as React.FC;
  return <Component />;
};

export default AuthRoute;
