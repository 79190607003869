import { ChangeEvent, FormEvent } from "react";
import {
  Calendar,
  DayValue,
} from "@hassanmojab/react-modern-calendar-datepicker";
import ShortTextInput from "components/Shared/fields/ShortTextInput";
import { appStrings } from "data/strings";
import PrimaryButton from "components/Shared/buttons/PrimaryButton";
import { FaPlusCircle } from "react-icons/fa";
import SwitchInput from "components/Shared/fields/SwitchInput";
import { genders } from "data/genders";

export interface InfantFormProps {
  name?: string;
  birthdate?: DayValue;
  gender?: string;
  loading: boolean;
  handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBirthdateChange: (value: DayValue) => void;
  handleGenderChange: (value: string) => void;
  onCreate: (event: FormEvent) => void;
}

const InfantForm: React.FC<InfantFormProps> = ({
  name,
  birthdate,
  gender,
  loading,
  handleNameChange,
  handleBirthdateChange,
  handleGenderChange,
  onCreate,
}) => {
  return (
    <div className="w-full lg:w-1/2 md:px-4 mt-12 lg:mt-0">
      <form className="flex flex-col space-y-4" onSubmit={onCreate}>
        <ShortTextInput
          name="babyName"
          label={appStrings.babyName}
          placeholder={appStrings.babyNamePlaceholder}
          value={name}
          onChange={handleNameChange}
        />
        <SwitchInput
          label={appStrings.gender}
          options={genders}
          selected={gender}
          onChange={handleGenderChange}
        />
        <p className="text-gray-900 font-semibold">{appStrings.birthdate}</p>
        <div>
          <Calendar
            value={birthdate}
            onChange={handleBirthdateChange}
            locale="fa"
            colorPrimary="#5B21B6"
          />
        </div>
        <PrimaryButton loading={loading}>
          <FaPlusCircle className="inline-block ml-2" />
          <span>{appStrings.addInfant}</span>
        </PrimaryButton>
      </form>
    </div>
  );
};

export default InfantForm;
