import Alert from "components/Layout/Alert";
import PrimaryButton from "components/Shared/buttons/PrimaryButton";
import { AlertTypes } from "data/alertTypes";
import { PasswordMethod } from "data/fields";
import { appStrings } from "data/strings";
import { ChangeEvent, FormEvent } from "react";
import Password from "components/Login/Password";
import PasswordChecker from "screens/Shared/utils/PasswordChecker";

export interface PasswordFormProps {
  method: PasswordMethod;
  value?: string;
  loading: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSetPassword: (event: FormEvent) => void;
  onVerify: (event: FormEvent) => void;
}

const PasswordForm: React.FC<PasswordFormProps> = ({
  method,
  value,
  loading,
  onChange,
  onSetPassword,
  onVerify,
}) => {
  const getHint = () => {
    switch (method) {
      case PasswordMethod.STATIC:
        return appStrings.changePasswordHint;
      case PasswordMethod.SMSCODE:
        return appStrings.codeHintPhone;
      case PasswordMethod.MAILCODE:
        return appStrings.codeHintMail;
      default:
        return appStrings.passwordHint;
    }
  };

  return (
    <div className="w-full lg:w-1/2 md:px-4 mt-12 lg:mt-0">
      <Alert type={AlertTypes.INFO} text={getHint()} />
      <form
        className="flex flex-col space-y-4 mt-8"
        onSubmit={method === PasswordMethod.STATIC ? onSetPassword : onVerify}
      >
        <div className="pb-4">
          <Password method={method} value={value} onChange={onChange} />
          {method === PasswordMethod.STATIC && value && (
            <PasswordChecker password={value} />
          )}
        </div>
        <PrimaryButton loading={loading}>
          {appStrings.changePassword}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default PasswordForm;
