export enum LabelPlacement {
  INLINE = "inline",
  TOP = "top",
}

export enum UsernameMethod {
  PHONE = "phone",
  EMAIL = "email",
  TEXT = "text",
}

export enum PasswordMethod {
  STATIC = "static",
  MAILCODE = "mailCode",
  SMSCODE = "smsCode",
}
