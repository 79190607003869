import Alert from "components/Layout/Alert";
import { AlertTypes } from "data/alertTypes";
import { appStrings } from "data/strings";

export interface DescriptionProps {
  isConfirmed?: boolean;
  loading?: boolean;
  onResend?: () => void;
}

const Description: React.FC<DescriptionProps> = ({
  isConfirmed = false,
  loading = false,
  onResend,
}) => {
  return (
    <div className="w-full lg:w-1/2 flex flex-col space-y-6 md:px-4 lg:mt-0">
      <p className="text-lg md:text-xl font-semibold">
        {appStrings.contact.title}
      </p>
      <p className="text-sm md:text-base text-justify leading-relaxed">
        {appStrings.contact.description}
      </p>
      {!isConfirmed && (
        <Alert type={AlertTypes.WARNING}>
          <p>{appStrings.linkHintMail}</p>
          <p className="mt-3">
            <span className="font-semibold cursor-pointer" onClick={onResend}>
              {appStrings.resendEmailLink}
              {loading && "..."}
            </span>
          </p>
        </Alert>
      )}
    </div>
  );
};

export default Description;
