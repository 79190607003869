import { INavMenu } from "models/NavMenu";
import { NavLink } from "react-router-dom";

export interface NavItemProps extends INavMenu {}

const NavItem: React.FC<NavItemProps> = ({
  title,
  url,
  icon,
  exact = false,
}) => {
  return (
    <NavLink
      to={url}
      exact={exact}
      className="flex flex-col space-y-2 mx-1 lg:mx-2 py-2 px-1 rounded-lg text-gray-800 outline-none transition duration-300 hover:bg-gray-100"
      activeClassName="text-purple-900"
    >
      <span className="flex justify-center text-xl">{icon}</span>
      <span className="flex justify-center text-xs lg:text-sm">{title}</span>
    </NavLink>
  );
};

export default NavItem;
