import { appStrings } from "data/strings";

const Description: React.FC = () => {
  return (
    <div className="w-full lg:w-1/2 flex flex-col space-y-6 md:px-4 lg:mt-0">
      <p className="text-lg md:text-xl font-semibold">
        {appStrings.infatnsHint.title}
      </p>
      <p className="text-sm md:text-base text-justify leading-relaxed">
        {appStrings.infatnsHint.description}
      </p>
      {/* <p className="text-sm md:text-base text-justify leading-relaxed">
        {appStrings.infatnsHint.note}
      </p> */}
    </div>
  );
};

export default Description;
