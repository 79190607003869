import PasswordStrength from "components/Shared/utils/PasswordStrength";
import { Strength } from "data/strengths";

export interface PasswordCheckerProps {
  password?: string;
}

const PasswordChecker: React.FC<PasswordCheckerProps> = ({ password = "" }) => {
  const strongRegex = new RegExp(
    "^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
    "g"
  );
  const mediumRegex = new RegExp(
    "^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
    "g"
  );

  const getStrength = () => {
    if (strongRegex.test(password)) {
      return Strength.STRONG;
    }
    if (mediumRegex.test(password)) {
      return Strength.MEDIUM;
    }
    return Strength.WEAK;
  };

  return <PasswordStrength strength={getStrength()} />;
};

export default PasswordChecker;
