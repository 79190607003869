import { ReactNode } from "react";
import Header from "./Header";

export interface ContentProps {
  children: ReactNode;
}

const Content: React.FC<ContentProps> = ({ children }) => {
  return (
    <div className="w-full min-h-full md:w-10/12 lg:w-2/3 2xl:w-1/2 bg-white rounded-xl shadow-lg lg:max-h-full lg:overflow-auto">
      <Header />
      <div className="w-2/3 h-1 bg-gradient-to-l from-blue-500 to-white"></div>
      {/*Divider*/}
      <div className="p-8">{children}</div>
    </div>
  );
};

export default Content;
