import { useLazyQuery } from "@apollo/client";
import PageLoader from "components/Shared/utils/PageLoader";
import { ME } from "graphql/queries/auth";
import { ReactNode, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authenticationState, tokenState, userState } from "recoil/atoms/auth";
import { infantsState } from "recoil/atoms/infant";
import { infantArrayParser } from "utils/infantParser";
import { userParser } from "utils/userParser";

export interface UserIdentifierProps {
  children: ReactNode;
}

const UserIdentifier: React.FC<UserIdentifierProps> = ({ children }) => {
  const setUser = useSetRecoilState(userState);
  const setInfants = useSetRecoilState(infantsState);
  const setAuth = useSetRecoilState(authenticationState);
  const token = useRecoilValue(tokenState);
  const [getMe, { loading, error }] = useLazyQuery(ME, {
    onError: () => setAuth(false),
    onCompleted: (data) => {
      if (data.me) {
        const parsedUser = userParser(data.me);
        const parsedInfants = infantArrayParser(data.me.infants);
        setUser(parsedUser);
        setInfants(parsedInfants);
        setAuth(true);
      } else {
        setAuth(false);
      }
    },
  });
  useEffect(() => {
    if (token) {
      getMe();
    }
  }, [getMe, token]);

  return (
    <PageLoader
      loading={loading || token === undefined}
      error={error}
      showError={false}
    >
      {children}
    </PageLoader>
  );
};

export default UserIdentifier;
