import { useState } from "react";

export type MaskCongfiguration = {
  mask: (value: string) => string;
  unmask: (value: string) => string;
  validator?: (value: string) => boolean;
  defaultValue?: string;
};

export function useMask({
  mask,
  unmask,
  validator,
  defaultValue = "",
}: MaskCongfiguration): [string, string, (value: string) => void] {
  const [value, setValue] = useState(mask(defaultValue));

  const onChange = (value: string) => {
    const unmasked = unmask(value);
    if (validator && validator(unmasked)) {
      setValue(mask(unmasked));
    }
  };

  return [unmask(value), value, onChange];
}
