import { AlertTypes } from "data/alertTypes";
import { ReactNode } from "react";
import { toPersian } from "utils/raqam";

export interface AlertProps {
  type: AlertTypes;
  text?: string;
  children?: ReactNode;
}

const Alert: React.FC<AlertProps> = ({ type, text = "", children }) => {
  const getTheme = () => {
    switch (type) {
      case AlertTypes.INFO:
        return "bg-blue-100 text-blue-700 border-blue-700";
      case AlertTypes.WARNING:
        return "bg-yellow-100 text-yellow-700 border-yellow-700";
      case AlertTypes.ERROR:
        return "bg-pink-100 text-pink-700 border-pink-700";
      default:
        return "bg-gray-200 text-gray-900 border-gray-900";
    }
  };

  return (
    <div
      className={`${getTheme()} py-2 px-3 border-r-4 text-justify leading-relaxed text-sm md:text-base`}
    >
      {children || <p>{toPersian(text)}</p>}
    </div>
  );
};

export default Alert;
