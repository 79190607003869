import { LabelPlacement } from "data/fields";
import FormItem from "./FormItem";
import { IOption } from "models/Option";
import { FaCircle, FaCheckCircle } from "react-icons/fa";

export interface SwitchInputProps {
  label?: string;
  labelPlacement?: LabelPlacement;
  selected?: string;
  options: IOption[];
  onChange: (value: string) => void;
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  label,
  selected,
  labelPlacement = LabelPlacement.TOP,
  options,
  onChange,
}) => {
  return (
    <FormItem label={label} labelPlacement={labelPlacement}>
      <div className="flex flex-row">
        {options.map(({ label, value }, index) => (
          <div
            key={index}
            onClick={() => onChange(value)}
            className="flex flex-row items-center mr-4 cursor-pointer"
          >
            <span className="ml-2">
              {value === selected ? (
                <FaCheckCircle className="text-blue-900" size={20} />
              ) : (
                <FaCircle className="text-gray-300" size={20} />
              )}
            </span>
            <span className="text-gray-900">{label}</span>
          </div>
        ))}
      </div>
    </FormItem>
  );
};

export default SwitchInput;
