import gql from "graphql-tag";

export const GET_INVOICE = gql`
  query FINANCE_get_invoice($uuid: ID!) {
    FINANCE_get_invoice(uuid: $uuid) {
      items {
        payload
      }
      payments {
        ref_id
      }
      subtotal
      total
    }
  }
`;
