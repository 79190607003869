import DangerButton from "components/Shared/buttons/DangerButton";
import { ButtonType } from "data/buttonTypes";
import { appStrings } from "data/strings";
import { CgLogOut } from "react-icons/cg";
import { useRecoilValue } from "recoil";
import { userState } from "recoil/atoms/auth";

export interface DescriptionProps {
  loading: boolean;
  onLogout: () => void;
}

const Description: React.FC<DescriptionProps> = ({ loading, onLogout }) => {
  const user = useRecoilValue(userState);
  const name =
    user && (user.firstName || user.lastName)
      ? user.firstName + " " + user.lastName
      : undefined;

  return (
    <div className="w-full lg:w-1/2 flex flex-col space-y-6 md:px-4 lg:mt-0">
      <p className="text-lg md:text-xl font-semibold">
        {appStrings.profileHint.title(name)}
      </p>
      <p className="text-sm md:text-base text-justify leading-relaxed">
        {appStrings.profileHint.description}
      </p>
      <DangerButton
        type={ButtonType.BUTTON}
        loading={loading}
        onClick={onLogout}
      >
        <CgLogOut className="inline-block ml-2 text-2xl" />
        {appStrings.logout}
      </DangerButton>
    </div>
  );
};

export default Description;
