import { Strength } from "data/strengths";
import { appStrings } from "data/strings";

export interface PasswordStrengthProps {
  strength: Strength;
}

const PasswordStrength: React.FC<PasswordStrengthProps> = ({ strength }) => {
  const getConfig = () => {
    switch (strength) {
      case Strength.STRONG:
        return {
          main: "bg-green-800 w-full",
          text: "text-green-800",
          title: appStrings.passwordStrength.strong,
        };
      case Strength.MEDIUM:
        return {
          main: "bg-yellow-500 w-2/3",
          text: "text-yellow-500",
          title: appStrings.passwordStrength.medium,
        };
      default:
        return {
          main: "bg-red-600 w-1/3",
          text: "text-red-600",
          title: appStrings.passwordStrength.weak,
        };
    }
  };

  return (
    <div className="flex flex-row items-center">
      <p className={`w-2/12 text-center bg-gray-200 py-1 text-sm ${getConfig().text}`}>
        {getConfig().title}
      </p>
      <div className="w-10/12">
        <div className={`w-full h-1 relative bg-gray-200`}>
          <div className={`absolute left-0 h-1 ${getConfig().main}`}></div>
        </div>
      </div>
    </div>
  );
};

export default PasswordStrength;
